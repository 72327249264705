// TODO: blue links for the SA108 to go to download center
// https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/1063019/sa108-2022.pdf
interface HelpCenterProps {
  faqs: {
    question: string;
    answer: string;
  }[];
}

export const FAQsUK: React.FC<HelpCenterProps> = () => {
  const faqs = [
    {
      question: "What’s the difference between SA100 & SA108 form?",
      answer:
        "SA100 is the main form for your self-assessment tax return. Use supplementary pages SA108 to record capital gains and losses on your SA100 Tax Return.",
    },
    {
      question: "How do I update my personal information?",
      answer:
        "You can update your personal information in the settings section of your account. If you need further assistance, please contact our customer support team.",
    },
  ];

  return (
    <div className="bg-white p-2 rounded-lg  w-full mb-4">
      <h2 className="text-lg font-medium text-gray-900 mb-4">
        Frequently asked questions
      </h2>
      <p className="text-sm text-gray-500 mb-6">
        Can’t find the answer you’re looking for? Reach out to our{" "}
        <a href="/support" className="text-green-600 underline">
          customer support
        </a>{" "}
        team
      </p>
      {faqs.map((faq, index) => (
        <div key={index} className="mb-4">
          <h3 className="text-md font-semibold text-gray-900 mb-2">
            {faq.question}
          </h3>
          <p className="text-sm text-gray-700">{faq.answer}</p>
        </div>
      ))}
    </div>
  );
};
export const FAQsUS: React.FC<HelpCenterProps> = () => {
  const faqs = [
    {
      question:
        "Do I need to report all cryptocurrency transactions to the IRS?",
      answer:
        "Yes, the IRS requires you to report cryptocurrency transactions if they result in taxable events, such as selling, trading, or using crypto to purchase goods or services. Even small transactions need to be reported if there’s a capital gain or loss.",
    },
    {
      question: "What’s the capital gains tax threshold for 2023?",
      answer:
        "For 2023, the capital gains tax rates are 0%, 15%, or 20%, depending on your taxable income and filing status. Short-term gains (on assets held for less than a year) are taxed as ordinary income, while long-term gains benefit from lower rates.",
    },
    {
      question: "How do I calculate my capital gains on cryptocurrency?",
      answer:
        "To calculate capital gains, subtract the cost basis (what you paid for the crypto) from the sale price. If you made a profit, you’ll have a capital gain, which is taxable. Losses can be used to offset gains.",
    },
    {
      question: "What form do I use to report cryptocurrency on my tax return?",
      answer:
        "You report cryptocurrency capital gains and losses on **Form 8949**, which is then summarized on **Schedule D** of your Form 1040.",
    },
    {
      question:
        "Are there any exemptions for reporting small amounts of capital gains?",
      answer:
        "No, there is no minimum threshold for reporting capital gains from cryptocurrency transactions. You must report all gains, regardless of the amount, to the IRS.",
    },
  ];

  return (
    <div className="bg-white p-2 rounded-lg w-full mb-4">
      <h2 className="text-lg font-medium text-gray-900 mb-4">
        Frequently asked questions
      </h2>
      <p className="text-sm text-gray-500 mb-6">
        Can’t find the answer you’re looking for? Reach out to our{" "}
        <a href="/support" className="text-green-600 underline">
          customer support
        </a>{" "}
        team
      </p>
      {faqs.map((faq, index) => (
        <div key={index} className="mb-4">
          <h3 className="text-md font-semibold text-gray-900 mb-2">
            {faq.question}
          </h3>
          <p className="text-sm text-gray-700">{faq.answer}</p>
        </div>
      ))}
    </div>
  );
};
