import React, { useState, useContext } from "react";
import clsx from "clsx";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { PrivacyModeContext } from "@/contexts";

import {
  ArrowsRightLeftIcon,
  ReceiptPercentIcon,
} from "@heroicons/react/24/outline";
import {
  TaxEventItemClass,
  TaxEvent,
  formatAmount,
  formatCurrency,
  useIsFiatCurrency,
} from "@moonscape/shared";

import { CoinIcon } from "@/components";

export const taxYearMapping = {
  "2024": "2023/24",
  "2023": "2022/23",
  "2022": "2021/22",
  "2021": "2020/21",
  "2020": "2019/20",
  "2019": "2018/19",
  "2018": "2017/18",
  "2017": "2016/17",
  "2016": "2015/16",
  "2015": "2014/15",
  "2014": "2013/14",
  "2013": "2012/13",
  "2012": "2011/12",
  "2011": "2010/11",
  "2010": "2009/10",
  "2009": "2008/09",
};

export interface TaxEventItemProps {
  txn_id: string;
  disposal_type: string;
  asset: string;
  tax_year: string;
  proceeds: string;
  gain: string;
  cost: string;
  fees: string;
  quantity: string;
  screen: "sm" | "md" | "lg";
  // for showing emtpy row i.e. no results
  isEmpty: boolean;
  text: string;
}

export const TaxEventRow = (taxEvent) => {
  const t = new TaxEventItemClass(taxEvent);
  const isFiatCurrency = useIsFiatCurrency();

  const { privacyMode, setPrivacyMode } = useContext(PrivacyModeContext);

  const openModal = () => {
    console.log("Opening modal for ", t);
  };

  const isGain = t.gain > 0;
  const isLoss = t.gain < 0;

  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="flex items-center justify-between bg-white border border-gray-100 mt-4 p-4 rounded-2xl w-full cursor-pointer hover:bg-gray-100"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={openModal}
      role="button"
      tabIndex="0"
    >
      <div className="flex items-center space-x-4">
        <CoinIcon
          coinSymbol={t?.asset?.toLowerCase()}
          className="w-10 h-10 rounded-full"
        />
        <div>
          <div className="flex flex-row items-center">
            <div className="text-md font-semibold text-gray-900">{t.asset}</div>
            <div
              className={clsx(
                "inline-block  px-2 py-1 mt-1 text-xs font-medium text-gray-400 rounded-2xl"
              )}
            >
              {t.disposal_type}
            </div>
          </div>
          <div className="text-xs font- text-gray-400">{t.formattedTime()}</div>
        </div>
      </div>
      <div className="flex flex-col space-y-0">
        <div className="flex justify-end">
          <div className="flex-1 text-right">
            <span className="text-xs font-medium text-gray-300">COST</span>
          </div>
          <div className="flex-1 text-right">
            <span
              className={clsx("text-sm font-medium text-gray-700", {
                "blur-md": privacyMode,
              })}
            >
              £{formatAmount(t.cost, null, isFiatCurrency)}
            </span>
          </div>
        </div>
        <div className="flex justify-end">
          <div className="flex-1 text-right px-2">
            <span className="text-xs font-medium text-gray-300">PROCEEDS</span>
          </div>
          <div className="flex-1 text-right">
            <span
              className={clsx("text-sm font-medium text-gray-600", {
                "blur-md": privacyMode,
              })}
            >
              £{formatAmount(t.proceeds, null, isFiatCurrency)}
            </span>
          </div>
        </div>

        <div className="flex justify-end">
          <div className="flex-1 text-right">
            <span className="text-xs font-medium text-gray-300">
              {isGain ? "GAIN" : "LOSS"}
            </span>
          </div>
          <div className="flex-1 text-right">
            <span
              className={clsx(
                "text-sm font-medium",
                { "text-green-700": isGain },
                { "text-red-700": isLoss },
                {
                  "blur-md": privacyMode,
                }
              )}
            >
              £{formatAmount(t.gain, null, isFiatCurrency)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
