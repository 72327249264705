import { CURRENT_TAX_YEAR, TAX_YEARS } from "../config/taxConfig";
import { TaxJurisdiction } from "../types";

export const getCurrentTaxYearCGTRate = (profile) => {
  return profile?.taxRates?.find((rate) => rate?.year === CURRENT_TAX_YEAR);
};

export const getTaxYearByValue = (value) => {
  const taxYearObj = TAX_YEARS?.find((year) => year.value === value);
  return taxYearObj ? taxYearObj.taxYear : null;
};

export const getCGTRateForCurrentYear = (profile) => {
  // for getting CGT rate from profile object
  const currentYear = new Date().getFullYear();
  // console.log("[tax select] cgt_rate", currentYear);
  const currentYearRate = profile?.taxRates?.find(
    (rate) => rate.year === currentYear
  );

  // console.log("[tax select] currentYearRate", currentYearRate);

  return currentYearRate?.rate;
};
