import React from "react";
import {
  TaxJurisdictionSelect,
  TaxFilingStatusSelect,
  TaxAccountingMethodSelect,
  TaxCGTRateSelect,
} from "@/screens/TradesScreen/TaxJurisdictionSelect";
import {
  Switch,
  Route,
  Link,
  useParams,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { useProfile, TaxJurisdiction } from "@moonscape/shared";

export const TaxSettings = () => {
  const { path, url } = useRouteMatch();

  console.log("tax path ", path);
  return (
    <Switch>
      <div>
        <Route exact path={`${path}`}>
          <TaxSettingsContent />
        </Route>
        <Route exact path={`${path}/new`}>
          <p>tax sub screen</p>
        </Route>
      </div>
    </Switch>
  );
};

const TaxSettingsContent = () => {
  const { profile } = useProfile();

  console.log("[tax] TaxSettingsContent", profile);
  return (
    <div>
      <div className="mt-10 divide-y divide-gray-200">
        <div className="space-y-1">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Tax Settings
          </h3>
          <p className="max-w-2xl text-sm text-gray-500">
            Configure your tax preferences and link relevant information for
            accurate tax calculations.
          </p>
        </div>
        <div className="mt-6">
          <dl className="divide-y divide-gray-200">
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
              <dt className="text-sm font-medium text-gray-500">
                Tax Jurisdiction
              </dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <span className="flex-grow"></span>

                <span className="ml-4 flex-shrink-0 flex items-start space-x-4">
                  <TaxJurisdictionSelect />
                </span>
              </dd>
            </div>
            {/* CGT rate for UK */}
            {profile?.taxJurisdiction === TaxJurisdiction.UK && (
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
                <dt className="col-span-2">
                  <p className="text-sm font-medium text-gray-500">CGT Rate</p>
                  <p className="max-w-2xl pt-1 text-xs text-gray-400">
                    Your CGT Rate is 10% if you're a basic rate taxpayer. <br />{" "}
                    Select 20% if you're a higher or additional rate taxpayer.{" "}
                    <br />
                    If your rate is different in previous years, send us a
                    message.
                  </p>
                  <p className="mt-1 text-xs">
                    <a
                      href="https://www.gov.uk/capital-gains-tax/rates"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="underline text-xs text-blue-500"
                    >
                      See HMRC for more information
                    </a>
                  </p>
                </dt>
                <dd className="mt-1 flex text-sm  text-gray-900 sm:mt-0 sm:col-span-1">
                  <span className="flex-shrink-0 flex ml-auto items-start">
                    {" "}
                    {/* ml-auto pushes to the right */}
                    <TaxCGTRateSelect />
                  </span>
                </dd>
              </div>
            )}
            {/* US settings */}
            {profile?.taxJurisdiction === TaxJurisdiction.US && (
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
                <dt className="text-sm font-medium text-gray-500">
                  Filing status
                </dt>
                <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <span className="flex-grow"></span>

                  <span className="ml-4 flex-shrink-0 flex items-start space-x-4">
                    <TaxFilingStatusSelect />
                  </span>
                </dd>
              </div>
            )}
            {profile?.taxJurisdiction === TaxJurisdiction.US && (
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
                <dt className="text-sm font-medium text-gray-500">
                  Accounting method
                </dt>
                <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <span className="flex-grow"></span>

                  <span className="ml-4 flex-shrink-0 flex items-start space-x-4">
                    <TaxAccountingMethodSelect />
                  </span>
                </dd>
              </div>
            )}
          </dl>
        </div>
      </div>
    </div>
  );
};
