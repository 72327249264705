// NOTE: check that positions/config/user_preferences/user_preferences_config.py has the same keys (and server - same filename)

// need to add these prefs to user_preferences db table

// general system wide preferences
const SYSTEM_WIDE_PREFERENCES = {
  SEND_EMAIL_ON_TAX_REPORT: "send_email_on_tax_report",
  SEND_EMAIL_ON_IMPORT_VIA_API_KEY: "send_email_on_import_via_api_key",
  SEND_EMAIL_ON_IMPORT_VIA_FILE: "send_email_on_import_via_file",
};

// web-only preferences
const WEB_ONLY_PREFERENCES = {
  // ENABLE_DARK_MODE: "enable_dark_mode",
  // SHOW_NOTIFICATIONS: "show_notifications",
};

// mobile-only preferences
const MOBILE_ONLY_PREFERENCES = {
  // ENABLE_PUSH_NOTIFICATIONS: "enable_push_notifications",
  // SYNC_OVER_WIFI_ONLY: "sync_over_wifi_only",
};

export const USER_PREFERENCES = {
  ...SYSTEM_WIDE_PREFERENCES,
  ...WEB_ONLY_PREFERENCES,
  ...MOBILE_ONLY_PREFERENCES,
};
