/** current tax year - TODO: should be used in conjunction to figure out US or UK since they end differently */
export const CURRENT_TAX_YEAR = 2024;

export const TAX_YEARS = [
  { taxYear: "2024/25", value: "2025" },
  { taxYear: "2023/24", value: "2024" },
  { taxYear: "2022/23", value: "2023" },
  { taxYear: "2021/22", value: "2022" },
  { taxYear: "2020/21", value: "2021" },
  { taxYear: "2019/20", value: "2020" },
  { taxYear: "2018/19", value: "2019" },
  { taxYear: "2017/18", value: "2018" },
  { taxYear: "2016/17", value: "2017" },
];
