import React from "react";
import {
  ExclamationTriangleIcon,
  CheckCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/solid";

type AlertVariant = "warning" | "info" | "success";

interface AlertProps {
  variant: AlertVariant;
  children: React.ReactNode;
}

export const Alert: React.FC<AlertProps> = ({ variant, children }) => {
  const styles = {
    warning: "bg-yellow-100 text-yellow-700",
    info: "bg-blue-100 text-blue-700",
    success: "bg-green-100 text-green-700",
  };

  return (
    <div className="flex items-center space-x-4 mb-4">
      <div className="flex-shrink-0">
        {variant === "warning" && (
          <div className="flex items-center justify-center w-10 h-10 bg-yellow-100 rounded-full">
            <ExclamationTriangleIcon
              className="h-5 w-5 text-yellow-400"
              aria-hidden="true"
            />
          </div>
        )}
        {variant === "info" && (
          <div className="flex items-center justify-center w-10 h-10 bg-blue-100 rounded-full">
            <InformationCircleIcon
              className="h-5 w-5 text-blue-400"
              aria-hidden="true"
            />
          </div>
        )}
        {variant === "success" && (
          <div className="flex items-center justify-center  w-10 h-10 bg-green-100 rounded-full">
            <CheckCircleIcon
              className="h-5 w-5 text-green-400"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
      <div className={`flex-1 p-2 px-4 rounded-3xl ${styles[variant]}`}>
        {children}
      </div>
    </div>
  );
};

// export const Alert = ({ text }) => {
//   return (
//     <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4 flex items-center">
//       <div className="flex-shrink-0">
//         <ExclamationTriangleIcon
//           className="h-5 w-5 text-yellow-400"
//           aria-hidden="true"
//         />
//       </div>
//       <div className="ml-3">{text}</div>
//     </div>
//   );
// };

export const AlertWithComponent = ({ children }) => {
  return (
    <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4 flex items-center">
      <div className="flex-shrink-0">
        <ExclamationTriangleIcon
          className="h-5 w-5 text-yellow-400"
          aria-hidden="true"
        />
      </div>
      {children}
    </div>
  );
};

export const SuccessAlert = ({ text }) => {
  return (
    <div className="rounded-sm bg-green-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <CheckCircleIcon
            className="h-5 w-5 text-green-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <p className="text-sm font-medium text-green-800">{text}</p>
        </div>
        <div className="ml-auto pl-3">
          <div className="-mx-1.5 -my-1.5">
            <button
              type="button"
              className="inline-flex bg-green-50 rounded-sm p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
            >
              <span className="sr-only">Dismiss</span>
              {/* <XMarkIcon className="h-5 w-5" aria-hidden="true" /> */}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
