import React, { useState, useEffect } from "react";
import { Switch } from "@headlessui/react";

interface ToggleSwitchProps {
  value: boolean;
  onPress: (enabled: boolean) => void;
}

export const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  value,
  onPress,
}) => {
  const [enabled, setEnabled] = useState(value);

  useEffect(() => {
    setEnabled(value);
  }, [value]);

  const handleChange = (value: boolean) => {
    console.log("[prefs] handleChange triggered with value:", value);
    setEnabled(value);
    onPress(value);
  };

  return (
    <Switch
      checked={enabled}
      onChange={handleChange}
      className={`${
        enabled ? "bg-green-600" : "bg-gray-200"
      } relative inline-flex h-6 w-11 items-center rounded-full`}
    >
      <span className="sr-only">Enable notifications</span>
      <span
        className={`${
          enabled ? "translate-x-6" : "translate-x-1"
        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
      />
    </Switch>
  );
};
