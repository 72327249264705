import {
  UseQueryResult,
  useQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { authorizedAxios } from "@/axios";
import { useAuth } from "@moonscape/shared";

import { Profile } from "@/types/moonscape";
import { minutes } from "@/utils";
import { toast } from "../utils";

interface ProfileOptions {
  refetchOnWindowFocus?: boolean;
}

export const useProfileQueryKey = ["profile"];

export const useProfile = (options?: ProfileOptions) => {
  const { user, isAuthenticated, isLoading, getToken } = useAuth();
  const email = user?.email;
  const queryClient = useQueryClient();

  const getProfile = async (): Promise<Profile> => {
    const url = `/profile`;
    try {
      const token = await getToken();
      const res = await authorizedAxios(token).get(url);
      return res.data;
    } catch (error) {
      console.log("[useProfile] error", error);
      throw error;
    }
  };

  const {
    isLoading: isQueryLoading,
    isIdle,
    isSuccess,
    error,
    isError,
    data: profile,
    refetch,
  }: UseQueryResult<Profile, unknown> = useQuery({
    queryKey: [useProfileQueryKey],
    queryFn: getProfile,
    enabled: !!email,
    refetchOnWindowFocus: !!options?.refetchOnWindowFocus,
    staleTime: 1 * minutes,
    refetchInterval: 5 * minutes,
  });

  const updateProfileTax = async (updatedProfile: {
    tax_jurisdiction?: string;
    tax_filing_status?: string;
    accounting_method?: string;
    cgt_rate?: string;
  }) => {
    const token = await getToken();
    const url = `/profile/tax`;

    console.log("updatedProfileTax", updatedProfile);
    const response = await authorizedAxios(token).post(url, updatedProfile);

    console.log("updateTax", response);
    if (!response.data) {
      throw new Error("Profile update failed");
    }

    return response.data;
  };

  const mutation = useMutation({
    mutationFn: updateProfileTax,
    onMutate: async (newProfile) => {
      toast.success("Updated tax settings");
    },
    //   await queryClient.cancelQueries(["profile"]);

    //   const previousProfile = queryClient.getQueryData<Record<string, any>>([
    //     "profile",
    //   ]);

    //   queryClient.setQueryData<Record<string, any>>(["profile"], (old) => {
    //     console.log("[profile] old ", old);
    //     const newProfileData = {
    //       ...old,
    //       ...newProfile,
    //     };
    //     console.log("[profile] new", newProfileData);
    //     return newProfileData;
    //   });

    //   return { previousProfile };
    // },
    onError: (err, newProfile, context) => {
      console.log("[profile] onError", err);
      queryClient.setQueryData(["profile"], context.previousProfile);

      // Show alert to user that there was an error updating the profile
      toast.error("Error updating profile. Please try again.");
    },
    onSettled: () => {
      queryClient.invalidateQueries(["profile"]);
    },
  });

  const setProfileTax = ({
    tax_jurisdiction,
    tax_filing_status,
    accounting_method,
    cgt_rate,
  }: {
    tax_jurisdiction?: string;
    tax_filing_status?: string;
    accounting_method?: string;
    cgt_rate?: number;
  }) => {
    mutation.mutate({
      tax_jurisdiction,
      tax_filing_status,
      accounting_method,
      cgt_rate,
    });
  };
  return {
    isQueryLoading,
    isIdle,
    error,
    profile,
    email,
    refetch,
    mutation,
    setProfileTax,
  };
};
