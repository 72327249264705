import React, { useEffect } from "react";
import { Button, ButtonLink } from "@/components";
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon, InformationCircleIcon } from "@heroicons/react/24/outline";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "@/axios";
import { useGetTaxFilingStatuses } from "@/hooks";
import { useProfile, useProfileQueryKey } from "@moonscape/shared";
import { Link, useHistory, useParams, useRouteMatch } from "react-router-dom";

import { useQueryClient } from "@tanstack/react-query";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";

export const SelectionComponent = ({
  options,
  selectedValue,
  setSelectedValue,
  handleSelect,
}) => {
  const handleSelectChange = async (e) => {
    let value = e.target.value; // Get the selected value
    setSelectedValue(value);
    handleSelect(e);
  };

  return (
    <div>
      <select
        id="select"
        name="select"
        className="text-xl  w-64 block  rounded-xl border-0 p-3  text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
        value={selectedValue}
        onChange={handleSelectChange}
      >
        <option value="">Select...</option>
        {options?.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export enum TaxSettingKey {
  TaxJurisdiction = "tax_jurisdiction",
  AccountingMethod = "accounting_method",
  TaxFilingStatus = "tax_filing_status",
  CGTRate = "cgt_rate",
}

export const TaxSettingProfileKey = {
  [TaxSettingKey.TaxJurisdiction]: "taxJurisdiction",
  [TaxSettingKey.AccountingMethod]: "accountingMethod",
  [TaxSettingKey.TaxFilingStatus]: "taxFilingStatus",
  [TaxSettingKey.CGTRate]: "cgtRate",
};

export const TaxSettingUpdateKey = {
  [TaxSettingKey.TaxJurisdiction]: "tax_jurisdiction",
  [TaxSettingKey.AccountingMethod]: "accounting_method",
  [TaxSettingKey.TaxFilingStatus]: "tax_filing_status",
  [TaxSettingKey.CGTRate]: "cgt_rate",
};

const taxJurisdictionOptions = [
  { value: "United Kingdom", label: "United Kingdom 🇬🇧" },
  { value: "United States", label: "United States 🇺🇸" },
];

export const taxSettingsOptions: Record<
  TaxSettingKey,
  { value: string; label: string }[]
> = {
  [TaxSettingKey.TaxJurisdiction]: [
    { value: "United Kingdom", label: "United Kingdom 🇬🇧" },
    { value: "United States", label: "United States 🇺🇸" },
  ],
  [TaxSettingKey.AccountingMethod]: [
    { value: "LIFO", label: "LIFO" },
    { value: "FIFO", label: "FIFO" },
    { value: "HIFO", label: "HIFO" },
  ],
  [TaxSettingKey.CGTRate]: [
    { value: "10", label: "10% (Basic rate)" },
    { value: "20", label: "20% (Higher rate)" },
  ],

  // Add other options as needed
};

interface TaxSelectProps {
  taxSettingKey: TaxSettingKey;
  options: { value: string; label: string }[];
  onSuccess?: () => void;
}

export const TaxFilingStatusSelect = ({
  onSuccess,
}: {
  onSuccess?: () => void;
}) => {
  const { filingStatuses } = useGetTaxFilingStatuses();

  console.log("[new] filingStatus", filingStatuses);
  const taxFilingStatusOptions = filingStatuses?.map((status) => ({
    value: status,
    label: status,
  }));

  return (
    <TaxSelect
      taxSettingKey={TaxSettingKey.TaxFilingStatus}
      options={taxFilingStatusOptions}
      onSuccess={onSuccess}
    />
  );
};
export const TaxAccountingMethodSelect = ({
  onSuccess,
}: {
  onSuccess?: () => void;
}) => {
  const key = TaxSettingKey.AccountingMethod;
  return (
    <TaxSelect
      taxSettingKey={key}
      options={taxSettingsOptions[key]}
      onSuccess={onSuccess}
    />
  );
};
export const TaxCGTRateSelect = ({ onSuccess }: { onSuccess?: () => void }) => {
  const key = TaxSettingKey.CGTRate;
  return (
    <TaxSelect
      taxSettingKey={key}
      options={taxSettingsOptions[key]}
      onSuccess={onSuccess}
    />
  );
};

const TaxSelect: React.FC<TaxSelectProps> = ({
  taxSettingKey,
  /** to get the select options either from a dict or from api */
  options,
  onSuccess,
}) => {
  const { profile, setProfileTax } = useProfile();

  const [open, setOpen] = useState(false); // for modal

  const profileKey = TaxSettingProfileKey[taxSettingKey];
  const [selectedValue, setSelectedValue] = useState(profile?.[profileKey]);

  useEffect(() => {
    // If the taxSettingKey is cgt_rate, pick out the rate from taxRates for the current year
    if (taxSettingKey === "cgt_rate" && profile?.taxRates) {
      const currentYear = new Date().getFullYear();
      console.log("[tax select] cgt_rate", currentYear);
      const currentYearRate = profile?.taxRates?.find(
        (rate) => rate.year === currentYear
      );

      // console.log("[tax select] currentYearRate", currentYearRate);
      setSelectedValue(currentYearRate);
    } else {
      setSelectedValue(profile?.[profileKey]);
    }
  }, [profile?.[profileKey]]);

  // console.log("[tax] selectedValue: ", selectedValue);

  const handleTaxSettingSelect = (e) => {
    let value = e.target.value; // Get the selected value

    const updatedProfileTax = {
      [TaxSettingUpdateKey[taxSettingKey]]: value,
    };

    const res = setProfileTax(updatedProfileTax);

    // console.log("Settings saved successfully", res);
    // alert(`${taxSettingKey} saved successfully`);
  };
  return (
    <div>
      <SelectionComponent
        options={options}
        selectedValue={selectedValue}
        setSelectedValue={setSelectedValue}
        handleSelect={handleTaxSettingSelect}
      />
      {/* list modals you might want to trigger onChangeSelect */}
      <TaxJurisdictionModal open={open} setOpen={setOpen} />
    </div>
  );
};

export const TaxJurisdictionSelect = ({
  onSuccess,
}: {
  onSuccess?: () => void;
}) => {
  const { profile, setProfileTax } = useProfile();
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);

  // console.log("[tax] profile", profile);

  const [selectedValue, setSelectedValue] = useState(profile?.taxJurisdiction);

  const taxJurisdictionOptions = [
    { value: "United Kingdom", label: "United Kingdom 🇬🇧" },
    { value: "United States", label: "United States 🇺🇸" },
  ];

  useEffect(() => {
    setSelectedValue(profile?.taxJurisdiction);
  }, [profile?.taxJurisdiction]);

  console.log("[tax] selectedValue: ", selectedValue);
  const handleTaxJurisdictionSelect = (e) => {
    let value = e.target.value; // Get the selected value

    console.log("selected value: ", value);

    const updatedProfileTax = {
      tax_jurisdiction: value,
    };
    const res = setProfileTax(updatedProfileTax);

    // console.log("Settings saved successfully", res);
    // alert("Tax jurisdiction saved successfully");
  };

  return (
    <div>
      <SelectionComponent
        options={taxJurisdictionOptions}
        selectedValue={selectedValue}
        setSelectedValue={setSelectedValue}
        handleSelect={handleTaxJurisdictionSelect}
      />
      <TaxJurisdictionModal open={open} setOpen={setOpen} />
    </div>
  );
};

// TaxSelectBanner
export const TaxSelectBanner = () => {
  const [buttonText, setButtonText] = useState("Save");
  const [open, setOpen] = useState(false);

  return (
    <Card className="p-2">
      <CardHeader>
        {/* <CardTitle>
          <Link to={`tax/cgt`}>Tax stats</Link>{" "}
        </CardTitle>
        <CardDescription>Where are you a tax resident?</CardDescription> */}
      </CardHeader>
      <CardContent>
        <div className="flex-row mb-4">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Select your tax jurisdiction
            <br />
          </h2>
          <p className="text-lg pt-2 font-normal tracking-tight text-gray-500 sm:text-2xl">
            Where are you a tax resident?
            <br />
          </p>
        </div>
        <div className="pt-6 py-2 mb-2 flex items-center gap-x-6 lg:mt-0 lg:flex-shrink-0">
          <TaxJurisdictionSelect onSuccess={() => setButtonText("Saved!")} />
          <Button
            // onClick={handleTaxJurisdictionSelect}
            buttonText={buttonText}
          />
        </div>

        <TaxJurisdictionModal open={open} setOpen={setOpen} />
      </CardContent>
    </Card>
  );
};

export const TaxJurisdictionModal = ({ open, setOpen }) => {
  const queryClient = useQueryClient();

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-100">
                    <InformationCircleIcon
                      className="h-6 w-6 text-blue-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      Great News: We're Bringing US Tax Calculations to You
                      Soon!
                    </Dialog.Title>
                    <div className="mt-2 text-left">
                      <p className="text-sm text-gray-500 ">
                        While we're not quite there yet, rest assured that soon
                        you'll be able to seamlessly track and calculate your
                        tax liability right here.
                        <br />
                        <br />
                        Stay tuned – we'll notify you as soon as this feature is
                        available.
                        <br />
                        <br />
                        In the meantime, continue enjoying the convenience of
                        managing all your crypto in one place with our app.
                        <br />
                        <br />
                        And remember, your feedback is invaluable to us! If you
                        have any specific requests or suggestions for the
                        upcoming tax feature, please let us know through the
                        feedback section in the menu bar.
                        <br />
                        <br />
                        We’re all ears!
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={() => {
                      setOpen(false);

                      setTimeout(async () => {
                        queryClient.invalidateQueries({
                          queryKey: [useProfileQueryKey],
                        });
                      }, 3000);
                    }}
                  >
                    Go back to dashboard
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
