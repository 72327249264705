import { useCallback } from "react";
import {
  UseQueryResult,
  useQueryClient,
  useQuery,
  useMutation,
} from "@tanstack/react-query";
import { authorizedAxios } from "@/axios";
import { useAuth, USER_PREFERENCES } from "@moonscape/shared";
// this is a web/native compatible toast i.e. toastHandler.native.ts
import { toast } from "../utils";

interface Preference {
  preference_key: string;
  preference_value: any;
}

export const useHandlePreferences = () => {
  const queryClient = useQueryClient();

  // get auth0 user details
  const { user, isAuthenticated, isLoading, getToken } = useAuth();

  const fetchAllPreferences = async (): Promise<Record<string, any>> => {
    const token = await getToken();

    const { data } = await authorizedAxios(token).get("/preferences");
    return data;
  };

  const fetchPreference = async (
    preference_key: string
  ): Promise<Preference> => {
    const token = await getToken();

    const { data } = await authorizedAxios(token).get(
      `/preferences/${preference_key}`
    );
    return data;
  };

  const updatePreference = async ({
    preference_key,
    preference_value,
  }: Preference): Promise<Preference> => {
    const token = await getToken();

    console.log("[prefs] updating prefs", preference_key, preference_value);
    const { data } = await authorizedAxios(token).post(`/preferences`, {
      preference_key,
      preference_value,
    });
    return data;
  };

  const {
    data: preferences,
    error: fetchError,
    isLoading: isFetching,
  } = useQuery<Record<string, any>, Error>({
    queryKey: ["preferences"],
    queryFn: fetchAllPreferences,
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  const mutation = useMutation({
    mutationFn: updatePreference,
    onMutate: async (newPreference: Preference) => {
      await queryClient.cancelQueries(["preferences"]);

      const previousPreferences = queryClient.getQueryData<Record<string, any>>(
        ["preferences"]
      );

      queryClient.setQueryData<Record<string, any>>(["preferences"], (old) => {
        console.log("[prefs] old ", old);
        const newPrefs = {
          ...old,
          [newPreference.preference_key]: newPreference.preference_value,
        };
        console.log("[prefs] new", newPrefs);
        return newPrefs;
      });

      return { previousPreferences };
    },
    onError: (err, newPreference, context) => {
      console.log("[prefs] onError", err);
      queryClient.setQueryData(["preferences"], context.previousPreferences);

      // Show alert to user that there was an error setting the preference
      toast.error("Error setting preference. Please try again.");
    },
    onSettled: () => {
      queryClient.invalidateQueries(["preferences"]);
    },
  });

  const setPreference = (preference_key: string, preference_value: any) => {
    mutation.mutate({ preference_key, preference_value });
  };

  /** returns a bool check for a */
  const isEnabled = useCallback(
    (preference_key: string): boolean => {
      console.log("[prefs] re-rendering isEnabled");
      const value = preferences?.[preference_key];
      console.log("[prefs] value", value);
      if (typeof value === "string") {
        try {
          return JSON.parse(value.toLowerCase()) === true;
        } catch (e) {
          console.error("[prefs] Error parsing preference value", e);
          return false;
        }
      }
      return value === true;
    },
    [JSON.stringify(preferences)]
  );

  return {
    preferences,
    fetchError,
    isFetching,
    setPreference,
    isEnabled,
  };
};
