import React from "react";
import dayjs from "dayjs";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import {
  AuditTradeItemClass,
  AuditTxn,
  TradeItemProps,
  Txn,
  TxnType,
} from "@moonscape/shared";
import { AuditTradeItemSmall } from "./AuditTradeItemSmall";

import { AuditTradeItemMedium } from "./AuditTradeItemMedium";

const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

type Props = {
  txn: Txn | AuditTxn;
  showBorderBottom?: boolean;
  screen: string;
  isEmpty?: boolean;
  text?: string;
  userId: string;
  side: string;
  id: string;
  isQueryLoading: boolean;
};

export const AuditTradeItem: React.FC<Props> = ({
  txn,
  screen, // if screen is sm for breakpoints
  isEmpty = false,
  isQueryLoading,
  text,
  userId,
  side,
  id,
}) => {
  const t = new AuditTradeItemClass(txn);

  //  if (!timestamp) return null // with this declaration trades doesn't shows while searching because there are no timestamp property

  const EmptyStateText = () => (
    <div className="flex flex-col items-center justify-center p-10">
      <div className="bg-gray-100 p-5 rounded-full">
        <MagnifyingGlassIcon
          className={`h-8 w-8  text-gray-900`}
          aria-hidden="true"
        />
      </div>
      <h2 className="mt-4 text-xl font-semibold text-gray-800">
        You're all caught up!
      </h2>
      <p className="tex-gray-600 py-2">
        There are no transactions that need your attention right now.
      </p>
    </div>
  );

  if (!isQueryLoading) {
    // empty state - no results
    if (isEmpty) {
      if (screen === "sm") {
        return (
          // sm screen
          <div className="flex-1 px-4 py-2 items-start text-left text-sm ">
            <EmptyStateText />
          </div>
        );
        // md screen
      } else if (screen === "md") {
        return (
          <td colSpan={10} className="flex-1 px-6 py-4 text-left">
            <div className="text-sm text-gray-900">
              <EmptyStateText />
            </div>
          </td>
        );
      }
    }
  }

  // for sm screen
  if (screen === "sm") {
    return <AuditTradeItemSmall t={t} isQueryLoading={isQueryLoading} />;
  } else {
    // for 'md' and larger screens
    return <AuditTradeItemMedium t={t} isQueryLoading={isQueryLoading} />;
  }
};

const tableHeadings = [
  // "Transaction",
  // "Type",
  "Side",
  "Buy Asset",
  "Sell Asset",
  "Buy Amount",
  "Sell Amount",
  "Date (UTC)",
  "Fiat value",
  "Fee Qty",
  "Fee Asset",
  "Fiat fee value",
  // "Fiat fee value note",
  "Issue",
  "", // empty
];

export const AuditTradeHeadings = () => (
  <thead className=" ">
    <tr>
      {tableHeadings.map((heading) => (
        <th className="px-3 py-3 bg-gray-50 text-center text-xs font-medium text-gray-500 lg:px-6 ">
          {heading}
        </th>
      ))}
    </tr>
  </thead>
);
