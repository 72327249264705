import { useQuery, useQueryClient } from "@tanstack/react-query";
import { authorizedAxios } from "@/axios";
import { useAuth } from "@moonscape/shared";

import { Apikeys } from "@/types/moonscape";

export const useAPIKeysQueryKey = ["apikeys"];
export const useGetLinkedExchangesQueryKey = ["linkedexchanges"];

/** hook that calls api to get the current api keys for a user - and any OAUTH keys */
export const useAPIKeysData = () => {
  // get auth0 user details
  const { user, isAuthenticated, isLoading, getToken } = useAuth();

  const email = user?.email; // userId

  // react-query fetch data function
  const getAccessKeyData = async (): Promise<Apikeys[]> => {
    console.log("getAccessKeyData", user?.email);
    const url = `/apikeys`;
    try {
      const token = await getToken();
      const res = await authorizedAxios(token).get(url);
      console.log("/apikeys res", res.data, user?.email);
      return res.data;
    } catch (error) {
      console.log("error get token", error);
      throw error;
    }
  };

  // get linked exchanges also
  // react-query fetch data function
  const getLinkedExchanges = async (): Promise<Apikeys[]> => {
    console.log("getLinkedExchanges", user?.email);
    const url = `/linked-exchanges`;
    try {
      const token = await getToken();
      const res = await authorizedAxios(token).get(url);
      console.log("/getLinkedExchanges res", res.data);
      return res.data;
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  };

  // do the useQuery

  // react-query - waiting on auth0 email field before triggering query
  const {
    isLoading: isQueryLoading,
    isIdle,
    isSuccess,
    error: accessKeyError,
    isError,
    data: apikeys,
    refetch,
  } = useQuery({
    queryKey: [useAPIKeysQueryKey],
    queryFn: getAccessKeyData,

    // The query will not execute until the userId exists
    enabled: !!email,

    refetchOnWindowFocus: false,
  });

  /** used to pre-load prices since they take time */
  const prefetchQueryData = async () => {
    const queryClient = useQueryClient();

    return await queryClient.prefetchQuery(
      [useGetLinkedExchangesQueryKey],
      getLinkedExchanges
    );
  };

  const {
    isLoading: isLinkedExchangesQueryLoading,
    isIdle: linkedExchangesIdle,
    // isSuccess,
    error: linkedExchangesError,
    isError: isLinkedExchangesError,
    data: linkedExchanges,
    // refetch,
  } = useQuery({
    queryKey: [useGetLinkedExchangesQueryKey],
    queryFn: getLinkedExchanges,

    // The query will not execute until the userId exists
    enabled: !!email,

    refetchOnWindowFocus: false,
  });

  console.log("useAPIKeysData error", accessKeyError, linkedExchangesError);

  return {
    isQueryLoading,
    isIdle,
    isError,
    error: accessKeyError || linkedExchangesError,
    apikeys,
    linkedExchanges,
    email,
    refetch,
    prefetchQueryData,
  };
};
