export type TaxEvent = {
  asset: string;
  cost: number;
  datetime: string;
  disposal_type: string;
  fees: number;
  gain: number;
  proceeds: number;
  quantity: number;
  tax_year: string;
  txn_id: number;
};

export enum AccountingMethod {
  FIFO = "FIFO",
  LIFO = "LIFO",
  HIFO = "HIFO",
}

export enum TaxJurisdiction {
  US = "United States",
  UK = "United Kingdom",
}

export interface Txn {
  buyCoinGeckoId?: string;
  buyasset?: string;
  buyqty?: number;
  datetime?: string;
  exchange?: string;
  ext_txn_id?: string;
  feeasset?: string;
  feeqty?: number;
  price?: number;
  sellCoinGeckoId?: string;
  sellasset?: string;
  sellqty?: number;
  side?: string;
  timestamp?: number;
  tradeid?: string;
  type?: string;
  wallet?: string;
}

export interface AuditTxn extends Txn {
  created_at?: string;
  error_category?: string;
  error_data?: {
    buyasset?: string;
    buyqty?: number;
    buyvalue?: number;
    buyvalueasset?: string;
    calculated_fee_value?: number;
    calculated_value?: number;
    datetime?: string;
    exchange?: string;
    feeasset?: string;
    feeqty?: number;
    feevalue?: number;
    final_feevalue?: number;
    final_value?: number;
    id?: number;
    importtype?: string;
    price?: number;
    sellasset?: string;
    sellqty?: number;
    sellvalue?: number;
    sellvalueasset?: string;
    side?: string;
    timestamp?: number;
    tradeid?: string;
    type?: string;
    valueasset?: string;
    valuenote?: string;
    valuerate?: number;
    wallet?: string;
  };
  error_msg?: string;
  error_type?: string;
  id?: number;
  txn_id?: string;
  updated_at?: string;
  user_id?: string;
}

export enum AuditType {
  All = "all",
  MissingFiat = "missing-fiat",
  MissingFees = "missing-fees",
  SellBuyImbalance = "sell-buy-imbalance",
  MissingDeposits = "missing-deposits",
  AbnormalTransactionVolume = "abnormal-transaction-volume",
  DuplicateTransactions = "duplicate-transactions",
  OrphanTransactions = "orphan-transactions",
  DataConsistencyErrors = "data-consistency-errors",
  UnusualUserActivity = "unusual-user-activity",
}

export enum AlertVariants {
  Warning = "warning",
  Info = "info",
  Success = "success",
}
// export type Txn = {
//   month: string;
//   tradeid: string;
//   datetime: string;
//   type: "buy" | "sell";
//   buyasset: string;
//   sellasset: string;
//   buyqty: number;
//   sellqty: number;
//   buyvalue: number;
//   sellvalue: number;
//   side: "buy" | "sell";
//   price: string;
//   exchange: string;
//   ext_txn_id: string;
// };

// display names
export enum Exchange {
  OKX = "OKX",
  Bybit = "Bybit",
  Upbit = "Upbit",
  Kraken = "Kraken",
  "Gate.io" = "Gate.io",
  HTX = "HTX",
  Bitfinex = "Bitfinex",
  KuCoin = "KuCoin",
  Coinbase = "Coinbase",
  Binance = "Binance",
}
export enum ExchangeKey {
  OKX = "okx",
  Bybit = "bybit",
  Upbit = "upbit",
  Kraken = "kraken",
  Gateio = "gateio",
  HTX = "htx",
  Bitfinex = "bitfinex",
  KuCoin = "kucoin",
  Coinbase = "coinbase",
  Binance = "binance",
}

export type ExchangeConnectionInfo = {
  exchange: Exchange;
  exchangename: string;
  type: string; // api or oauth
};

export enum TxnType {
  DEPOSIT = "deposit",
  WITHDRAWAL = "withdrawal",
  TRADE = "trade",
  BUY = "buy",
  SELL = "sell",
  INTEREST = "interest",
  STAKING_REWARD = "staking reward",
  ADVANCED_TRADE_FILL = "advanced trade fill",
  ADVANCED_TRADE_ORDER = "advanced trade order",
}

export enum FormattedTxnType {
  DEPOSIT = "Deposit",
  WITHDRAWAL = "Withdrawal",
  TRADE = "Trade",
  BUY = "Buy",
  SELL = "Sell",
  INTEREST = "Interest",
}

export interface TradeItemProps {
  id: string;
  tradeid: string;
  type: string;
  exchange: string;
  timestamp: string;
  datetime: string;
  buyasset: string;
  buyqty: string;
  sellasset: string;
  sellqty: string;
  feeqty: string;
  feeasset: string;
  wallet: string;
  side: string;
  userId: string;
  screen: "sm" | "md" | "lg";
  // for showing emtpy row i.e. no results
  isEmpty: boolean;
  text: string;
}

export const exchangeInfoList: Record<Exchange, ExchangeWithDisplayName> = {
  [ExchangeKey.Binance]: { exchangename: "Binance", exchange: "binance" },
  [ExchangeKey.Bitfinex]: { exchangename: "Bitfinex", exchange: "bitfinex" },
  [ExchangeKey.Bybit]: { exchangename: "Bybit", exchange: "bybit" },
  [ExchangeKey.Coinbase]: { exchangename: "Coinbase", exchange: "coinbase" },
  [ExchangeKey.Gateio]: { exchangename: "Gate.io", exchange: "gateio" },
  [ExchangeKey.HTX]: { exchangename: "HTX", exchange: "htx" },
  [ExchangeKey.Kraken]: { exchangename: "Kraken", exchange: "kraken" },
  [ExchangeKey.KuCoin]: { exchangename: "KuCoin", exchange: "kucoin" },
  [ExchangeKey.OKX]: { exchangename: "OKX", exchange: "okx" },
  [ExchangeKey.Upbit]: { exchangename: "Upbit", exchange: "upbit" },
};
export type ExchangeWithDisplayName = Exchange & {
  displayName: string;
  primaryKey: string;
};

const exchangeMap: Record<Exchange, ExchangeWithDisplayName> = {
  [Exchange.OKX]: { displayName: "OKX", primaryKey: "okx" },
  [Exchange.Bybit]: { displayName: "Bybit", primaryKey: "bybit" },
  [Exchange.Upbit]: { displayName: "Upbit", primaryKey: "upbit" },
  [Exchange.Kraken]: { displayName: "Kraken", primaryKey: "kraken" },
  [Exchange["Gate.io"]]: { displayName: "Gate.io", primaryKey: "gateio" },
  [Exchange.HTX]: { displayName: "HTX", primaryKey: "htx" },
  [Exchange.Bitfinex]: { displayName: "Bitfinex", primaryKey: "bitfinex" },
  [Exchange.KuCoin]: { displayName: "KuCoin", primaryKey: "kucoin" },
  [Exchange.Coinbase]: { displayName: "Coinbase", primaryKey: "coinbase" },
  [Exchange.Binance]: { displayName: "Binance", primaryKey: "binance" },
};

export const exchanges: ExchangeWithDisplayName[] = Object.values(exchangeMap);

export const exchangeByDisplayName: Record<string, ExchangeWithDisplayName> =
  exchanges.reduce((acc, exchange) => {
    acc[exchange.displayName] = exchange;
    return acc;
  }, {});

export const exchangeByPrimarykey: Record<string, ExchangeWithDisplayName> =
  exchanges.reduce((acc, exchange) => {
    acc[exchange.primaryKey] = exchange;
    return acc;
  }, {});

// Example usage
const selectedExchangeByName: ExchangeWithDisplayName =
  exchangeByDisplayName["OKX"];
console.log(selectedExchangeByName.displayName); // Output: "OKX"
console.log(selectedExchangeByName.primaryKey); // Output: "okx"

const selectedExchangeByPrimarykey: ExchangeWithDisplayName =
  exchangeByPrimarykey["okx"];
console.log(selectedExchangeByPrimarykey.displayName); // Output: "OKX"
console.log(selectedExchangeByPrimarykey.primaryKey); // Output: "okx"
