// @ts-nocheck

import axiosLib from "axios";

/** instance for protected/authenticated endpoints */
export const axios = axiosLib.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
});
console.log("creating axios with base url", process.env.REACT_APP_SERVER_URL);

/** function to set the instances headers when we get the access token */
export const setAuthToken = (token) => {
  console.log("[axios] set access token");
  if (token) {
    //applying token
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    //deleting the token from header
    delete axios.defaults.headers.common["Authorization"];
  }
};

/** sets the header in the components after calling getAccessTokenSilently() */
export const authorizedAxios = (token) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  return axios;
};

export default axios;
