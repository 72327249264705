import React, { useEffect, useState, useContext } from "react";
import clsx from "clsx";
import { PrivacyModeContext } from "@/contexts";

import { BrowserRouter as Router, Link } from "react-router-dom";

import moonscapelogo from "@/moonscape.svg";
import { MoonscapeLogoText } from "./MoonscapeLogoText";
import navigation from "@/navigation";
import {
  Cog6ToothIcon,
  UserCircleIcon,
  ChatBubbleLeftEllipsisIcon,
  ExclamationTriangleIcon,
  PlusCircleIcon,
  ChatBubbleLeftRightIcon,
  EnvelopeOpenIcon,
  EnvelopeIcon,
} from "@heroicons/react/24/outline";
import { ProgressBar } from "../common";
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  CloudArrowDownIcon,
} from "@heroicons/react/24/solid";
import {
  AuditType,
  useAPIKeysData,
  useAuditPagination,
  useProfile,
} from "@moonscape/shared";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

// const navigation = [
//   { name: "Dashboard", href: "#", icon: HomeIcon, current: true },
//   { name: "Team", href: "#", icon: UsersIcon, current: false },
//   { name: "Projects", href: "#", icon: FolderIcon, current: false },
//   { name: "Calendar", href: "#", icon: CalendarIcon, current: false },
//   { name: "Documents", href: "#", icon: DocumentDuplicateIcon, current: false },
//   { name: "Reports", href: "#", icon: ChartPieIcon, current: false },
// ];
const teams = [
  { id: 1, name: "Binance", href: "#", initial: "B", current: false },
  { id: 2, name: "Coinbase", href: "#", initial: "C", current: false },
  {
    id: 3,
    name: "Kraken",
    href: "/transactions/kraken",
    initial: "K",
    current: false,
  },
];
const userNavigation = [
  { name: "Your profile", href: "#" },
  { name: "Sign out", href: "#" },
];
const alerts = [
  {
    name: "Transaction Audit",
    href: "/audit",
    icon: ExclamationTriangleIcon,
    current: false,
  },
];

const syncCompleted = false;
const progress = 80;
const jobStatus = {
  totalProgress: progress,
};
const syncTimeAgo = "1 hour ago";

function getExchangeValues(data) {
  let values = [];
  let index = 0;
  if (data) {
    if (Array.isArray(data.oauth)) {
      values = values.concat(
        data.oauth.map((item) => {
          index++;
          return {
            id: index + 1,
            name: item.exchangename,
            href: `/transactions/${item.exchangename.toLowerCase()}`,
            initial: item.exchangename.charAt(0),
            current: false,
          };
        })
      );
    }
    if (Array.isArray(data.api)) {
      values = values.concat(
        data.api.map((item) => {
          index++;
          return {
            id: index + 1,
            name: item.exchangename,
            href: `/transactions/${item.exchangename.toLowerCase()}`,
            initial: item.exchangename.charAt(0),
            current: false,
          };
        })
      );
    }
  }

  return values;
}

export const DesktopSidebar = () => {
  const [collapsed, setCollapsed] = useState(() => {
    const savedState = localStorage.getItem("sidebarCollapsed");
    return savedState ? JSON.parse(savedState) : false;
  });
  const { privacyMode, setPrivacyMode } = useContext(PrivacyModeContext);

  useEffect(() => {
    localStorage.setItem("sidebarCollapsed", JSON.stringify(collapsed));
  }, [collapsed]);

  // check if hasAuditTxns
  const {
    auditTransactions: auditTxns,
    isQueryLoading: auditIsQueryLoading,
    error: auditError,
    items,
    // pagination handlers
    previousPagination,
    nextPagination,
    resetPagination,
    // pagination data
    hasMore,
    remaining,
    page,
    setPage,
    isPreviousData,
  } = useAuditPagination({
    exchange: null,
    asset: null,
    auditType: AuditType.All,
    isSearching: false,
    searchedValue: null,
    onLoading: () => {
      // onLoadingHandler();
      // console.log("onLoadingHandler");
    },
  });
  const hasAuditTxns = auditTxns?.items?.length > 0;

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  const {
    isQueryLoading,
    error: queryError,
    apikeys,
    email,
    linkedExchanges,
  } = useAPIKeysData();

  const {
    isQueryLoading: isProfileQueryLoading,
    error: profileQueryError,
    profile,
  } = useProfile();

  console.log("[linked] linked exchanges", getExchangeValues(linkedExchanges));

  console.log(
    "[linked] linkedExchangeAccounts",
    profile?.linkedExchangeAccounts
  );

  const linkedExchangesArr = getExchangeValues(profile?.linkedExchangeAccounts);

  console.log("[linked] linkedExchangesArr", linkedExchangesArr);

  // const exchangeMenuItems = createExchangeDict(profile?.linkedExchangeAccounts);
  const exchangeMenuItems = linkedExchangesArr;

  return (
    <>
      {/* // sm mobile screen */}
      {/* Navbar */}

      {/* // md and lg screens */}
      <div className="hidden md:flex lg:flex lg:flex-shrink-0">
        {/* <div className="flex flex-col w-60"> */}
        <div
          className={clsx("flex flex-col", {
            "w-60": !collapsed,
            "w-24 text-center": collapsed,
          })}
        >
          <div className="flex flex-col h-0 flex-1 overflow-y-auto bg-gray-800">
            <div className="flex-1 flex flex-col">
              <div className="flex-shrink-0 bg-gray-800 py-4 flex items-center  flex-start ml-6">
                <img className="h-8 w-auto" src={moonscapelogo} alt="Logo" />
                {!collapsed && (
                  <MoonscapeLogoText className="ml-4 text-gray-200 w-24 md:h-8 md:w-28" />
                )}
              </div>

              {/* sidebar container */}
              <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-800 px-6 pb-4 pt-8">
                <nav className="flex flex-1 flex-col">
                  <ul className="flex flex-1 flex-col gap-y-7">
                    <li>
                      <ul className=" space-y-1">
                        {navigation.map((item) => (
                          <li key={item.name}>
                            <Link
                              to={item.href}
                              className={classNames(
                                item.current
                                  ? "bg-gray-800 text-white"
                                  : "text-gray-400 hover:text-white hover:bg-gray-700", // Updated hover color to gray-700
                                "group flex gap-x-3 rounded-md p-2 text-md leading-6 font-semibold",
                                {
                                  "justify-center": collapsed, // Add 'justify-center' class when collapsed is true and name doesn't exist
                                }
                              )}
                            >
                              <item.icon
                                className="h-6 w-6 shrink-0"
                                aria-hidden="true"
                              />
                              {!collapsed && item.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </li>

                    {/* transaction audit and other items that need attention  */}
                    <li>
                      {/* section for transaction audit or things that need attention */}
                      <ul className=" mt-2 mb-2 space-y-1">
                        {hasAuditTxns &&
                          alerts.map((alert, index) => (
                            <Link
                              to={alert.href}
                              className={classNames(
                                alert.current
                                  ? "bg-gray-800 text-white"
                                  : "text-gray-400 hover:text-white hover:bg-gray-700", // Updated hover color to gray-700
                                "group flex gap-x-3 rounded-md p-2 text-md leading-6 font-semibold"
                              )}
                              key={index}
                            >
                              <alert.icon
                                className="h-6 w-6 shrink-0 text-red-500" // Added text-red-500 to make the icon red
                                aria-hidden="true"
                              />
                              {!collapsed && alert.name}
                            </Link>
                          ))}
                      </ul>
                    </li>
                    <li>
                      <div className="text-xs font-semibold leading-6 text-gray-400">
                        {!collapsed && "Your exchanges"}
                      </div>
                      <ul className=" mt-2 space-y-1">
                        {exchangeMenuItems.map((exchange) => (
                          <li key={exchange.name}>
                            <Link
                              to={exchange.href}
                              className={classNames(
                                exchange.current
                                  ? "bg-gray-800 text-white"
                                  : "text-gray-400 hover:text-white hover:bg-gray-700", // Updated hover color to gray-700
                                "group flex gap-x-3 rounded-md p-2 text-md leading-6 font-semibold"
                              )}
                            >
                              <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">
                                {exchange.initial}
                              </span>
                              <span
                                className={clsx("truncate", {
                                  "blur-md": privacyMode, // Add blur-md class when privacyMode is true
                                })}
                              >
                                {!collapsed && exchange.name}
                              </span>
                            </Link>
                          </li>
                        ))}
                        {/* Connect new exchange  */}
                        <li key="connect-exchange">
                          <Link
                            to="/settings/exchange/new"
                            className={classNames(
                              false
                                ? "bg-gray-800 text-white"
                                : "text-gray-400 hover:text-white hover:bg-gray-700", // Updated hover color to gray-700
                              "group flex gap-x-3 rounded-md p-2 text-md leading-6 font-semibold"
                            )}
                          >
                            <PlusCircleIcon
                              className="h-6 w-6 shrink-0"
                              aria-hidden="true"
                            />
                            <span className="truncate">
                              {!collapsed && "Link exchange"}
                            </span>
                          </Link>
                        </li>
                        <SyncProgress />
                      </ul>
                    </li>

                    <ul className="mt-auto">
                      <li className="mt-2 text-xs">
                        <a
                          href="https://discord.gg/zeRH9TDhmd"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="group  flex gap-x-3 items-center rounded-md p-2 text-md font-semibold leading-6 text-gray-400 hover:bg-gray-700 hover:text-white" // Updated hover color to gray-700
                        >
                          <ChatBubbleLeftEllipsisIcon
                            className="h-6 w-6 shrink-0"
                            aria-hidden="true"
                          />
                          {!collapsed && (
                            <div className="flex-col text-xs">
                              Join Discord
                              {/* <span className="text-xs ml-1 block font-semibold text-gray-500">
                                Leave feedback
                              </span> */}
                            </div>
                          )}
                        </a>
                      </li>
                      <li className="border-b border-gray-700 text-xs ">
                        <a
                          href="mailto:hello@moonscape.app?subject=Moonscape feedback: "
                          className="group mb-2 flex gap-x-3 rounded-md p-2 text-md font-semibold leading-6 text-gray-400 hover:bg-gray-700 hover:text-white" // Updated hover color to gray-700
                        >
                          <EnvelopeIcon
                            className="h-6 w-6 shrink-0"
                            aria-hidden="true"
                          />
                          {!collapsed && "Share your feedback"}
                        </a>
                      </li>
                      <li className="mt-2">
                        <Link
                          to="/settings/exchange"
                          className="group  flex gap-x-3 rounded-md p-2 text-md font-semibold leading-6 text-gray-400 hover:bg-gray-700 hover:text-white" // Updated hover color to gray-700
                        >
                          <Cog6ToothIcon
                            className="h-6 w-6 shrink-0"
                            aria-hidden="true"
                          />
                          {!collapsed && "Settings"}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/profile"
                          className="group  flex gap-x-3 rounded-md p-2 text-md font-semibold leading-6 text-gray-400 hover:bg-gray-700 hover:text-white" // Updated hover color to gray-700
                        >
                          <UserCircleIcon
                            className="h-6 w-6 shrink-0"
                            aria-hidden="true"
                          />
                          {!collapsed && "Profile"}
                        </Link>
                      </li>
                      <li>
                        <button
                          onClick={toggleSidebar}
                          className="w-full group  flex gap-x-3 rounded-md p-2 text-md font-semibold leading-6 text-gray-400 hover:bg-gray-700 hover:text-white"
                          aria-label="Toggle Sidebar"
                        >
                          {collapsed ? (
                            <ChevronDoubleRightIcon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          ) : (
                            <ChevronDoubleLeftIcon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          )}
                        </button>
                      </li>
                    </ul>
                  </ul>
                </nav>
              </div>
            </div>
            <div className="flex-shrink-0 flex pb-2"></div>
          </div>
        </div>
      </div>
    </>
  );
};

const SyncProgress = () => {
  return null;
  return (
    <div className="">
      <li
        key="connect-exchange"
        className="mt-4 pl-3 pr-4 pt-4 py-3 border rounded-xl border-gray-600"
      >
        <CloudArrowDownIcon
          className="h-8 w-8 shrink-0 inline p-1  mr-1 text-gray-400"
          aria-hidden="true"
        />
        <span className="text-sm font-semibold text-gray-400 pb-1">
          Sync in Progress...
        </span>
        <div className="py-2 flex items-center">
          <ProgressBar
            // key={exchange}
            // completed={jobStatus?.totalProgress}
            completed={() => {
              if (syncCompleted) {
                return 100;
              }

              if (progress < 6) {
                return 6;
              }
              if (!progress) {
                return 1;
              }
              if (progress > 100) {
                return 100;
              }
              return progress;
            }}
            bgcolor="green"
          />
        </div>
        {/* {jobStatus?.totalProgress === 100 && (
                              <span className="flex-grow text-xs">
                                Last sync: {syncTimeAgo}
                              </span>
                            )} */}
      </li>
    </div>
  );
};
